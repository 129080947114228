<template>
  <div class="mess-continer">
    <div class="mess-head">
       <el-form ref="form" style="height: 64px;" :model="form" label-width="80px">
      <div class="head-input">
  <el-form-item>
     <span slot="label">
      	<span class="text">消息标题 :</span>
      </span>
    <el-input size="mini" placeholder="请输入消息标题" v-model="form.name"></el-input>
  </el-form-item>
      </div>
      <div class="head-input">
  <el-form-item>
     <span slot="label">
      	<span class="text">发送方式 :</span>
      </span>
    <el-select size="mini" v-model="form.region" placeholder="请选择活动区域">
      <el-option label="区域一" value="shanghai"></el-option>
      <el-option label="区域二" value="beijing"></el-option>
    </el-select>
  </el-form-item>
      </div>
      <div class="head-input">
  <el-form-item>
     <span slot="label">
      	<span class="text">发送状态 :</span>
      </span>
      <el-select size="mini" v-model="form.region" placeholder="请选择活动区域">
      <el-option label="区域一" value="shanghai"></el-option>
      <el-option label="区域二" value="beijing"></el-option>
    </el-select>
  </el-form-item>
      </div>
           <div class="mess-butt">
           <el-button size="mini" type="primary">重置</el-button>
             <el-button size="mini" type="primary">搜索</el-button>
        </div>
        </el-form>
   
    </div>
    <div class="mess-center">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
  <el-menu-item index="1">处理中心</el-menu-item>
  </el-menu>
  <div style="margin-top:20px">
     <el-table
    :data="tableData"
    border
    style="width: 100%">
    <el-table-column
      fixed
      prop="date"
      label="日期"
      width="150">
    </el-table-column>
    <el-table-column
      prop="name"
      label="姓名"
      width="120">
    </el-table-column>
    <el-table-column
      prop="province"
      label="省份"
      width="120">
    </el-table-column>
    <el-table-column
      prop="city"
      label="市区"
      width="120">
    </el-table-column>
    <el-table-column
      prop="address"
      label="地址"
      width="300">
    </el-table-column>
    <el-table-column
      prop="zip"
      label="邮编"
      width="120">
    </el-table-column>
    <el-table-column
      fixed="right"
      label="操作"
      width="140">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
        <el-button type="text" size="small">编辑</el-button>
        <el-button type="text" size="small">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  </div>
    </div>
  </div>
</template>

<script>
  export default {
     data() {
      return {
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
         activeIndex: '1',
         tableData: [{
          date: '2016-05-02',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333
        }, {
          date: '2016-05-04',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1517 弄',
          zip: 200333
        }, {
          date: '2016-05-01',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1519 弄',
          zip: 200333
        }, {
          date: '2016-05-03',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1516 弄',
          zip: 200333
        }]
      }
    },
  }
</script>

<style  scoped>
.mess-continer{
  width: 100%;
  height: 100%;
  background: #DCDCDC;
  padding: 20px 10px 0 10px;
}
.mess-head{
  width: 100%;
  height: 62px;
  background: white;
  overflow: hidden;
}
.head-input{
  width: 270px;
  height: 32px;
  margin-top: 15px;
  margin-right: 15px;
  float: left;
}
.text{
  font-size: 14px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #333333;
}
.mess-butt{
  float: left;
  margin-top: 22px;
}
.mess-center{
  width: 100%;
  height: 600px;
  background: white;
  margin-top: 10px;
}
</style>